export function toggleClass(e, className, b) {
    if (typeof (b) === 'undefined') {
        b = !e.classList.contains(className);
    }
    if (b) {
        e.classList.add(className);
    } else {
        e.classList.remove(className);
    }
}

export function formatPrice(num) {
    return new Intl.NumberFormat(
        undefined, {minimumFractionDigits: 2}
    ).format(parseFloat(num));
}

export function is_expired(ts, ttl) {
    return time() - ts > ttl*1000;
}

export function time() {
    return (new Date()).getTime();
}

export const forEach = Array.prototype.forEach;
NodeList.prototype.forEach = forEach;

export function addEvent(el, type, handler) {
    if (!(el instanceof NodeList) && !(el instanceof Array)) {
        el = new Array(el);
    }
    for (let i = 0; i < el.length; i++) {
        if (el[i].attachEvent) el[i].attachEvent(`on${type}`, handler); else el[i].addEventListener(type, handler);
    }
}

export function addClickEvent(view, selector, handler) {
    addEvent(view.querySelectorAll(selector), 'click', handler);
}

export function is_empty(obj) {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

export function deepcopy(data) {
    return JSON.parse(JSON.stringify(data));
}


export function if_mobile_app(text) {
    return window && window._app && window._app.settings.mobile_app
        ? text
        : '';
}

export function smart_time(dateStr) {
    let date;
    try {
        date = new Date(dateStr);
    }
    catch(err) {
        return '';
    }
    const curdate = new Date();
    const timeDiffH = (curdate.getTime() - date.getTime()) / 60 / 60 / 1000;
    if (timeDiffH > 24 + curdate.getHours()) {
        return date_format(dateStr);
    }
    if (Math.floor(timeDiffH) > curdate.getHours()) {
        return _app.lang.yesterday +' '+ time_format(date);
    }
    return time_format(dateStr);
}

export function smart_time_utc(dateStr) {
    if (!dateStr.match(/(GMT|[\+\-][\d\:]+|Z)$/)) {
        dateStr += 'Z';
    }
    return smart_time(dateStr);
}

function getLang() {
    const m = (
        window._app ? window._app.settings.lang : navigator.language
    ).match(/^(\w+)/);
    return m ? m[1] : 'en';
}

export function date_format(date) {
    const [year, month, day] = (date+'').split('-');
    const d = new Date();
    const lang = getLang();

    if (!year || !month || !day) return '';
    let month_name;
    try  {
        month_name = flatpickr.l10ns[lang].months.shorthand[
            parseInt(month)-1
        ]
    }
    catch(err) {}
    if (month_name) {
        const printYear = Math.abs(year - (new Date()).getYear() - 1900) > 0
            ? year
            : '';
        return `${parseInt(day)} ${month_name}${printYear ? ' ' + printYear : ''}`;
    }
    d.setDate(1);
    d.setYear(year);
    d.setMonth(month-1);
    d.setDate(day);
    return d.toLocaleDateString();
}

export function time_format(date) {
    let dateStr = date + '';
    if (dateStr.match(/(GMT|[\+\-][\d\:]+|Z)$/)) {
        dateStr = (new Date(dateStr)).toISOString().slice(11, 16);
    }
    const m = dateStr.match(/(\d+:\d+)/);
    if (m) {
        return m[1];
    } else {
        return ''
    }
}

export function time_delta(minutes) {
    if (minutes < 0) {
        return document.getElementById('chat-local-timer-fired')
            .textContent.trim();
    }
    if (minutes < 1) {
        return _app.lang.less_than_1_min;
    }
    if (minutes < 60) {
        return `${Math.round(minutes)} ${_app.lang.mins}`
    }
    if (minutes < 5*60) {
        return `${Math.floor(minutes/60) } ${_app.lang.hours}${
            Math.round(minutes) % 60 > 0
                ? ` ${Math.round(minutes) % 60 } ${_app.lang.mins}`
                : ''
            }`
    }
    if (minutes < 48*60) {
        return `${Math.round(minutes/60)} ${ _app.lang.hours }`;
    }

    return `${Math.round(minutes/60/24)} ${ _app.lang.days }`;
}

export function i18n(text) {
    return (window._app && window._app.lang[text]) ? window._app.lang[text] : text;
}

export function date_time_format(date) {
    const d = new Date(date);
    return `${date_format(date)}  ${new Array(
        d.getHours() < 10 ? `0${d.getDate()}` : d.getDate(),
        d.getMinutes() < 10 ? `0${d.getDate()}` : d.getDate()
    ).join(':')}`;
}

export function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function env() {
    console.log('env called');
    return _app.params;
}

export function last_4_digits(text) {
    const match = (`${text}`).match(/(\d{4})\D*$/);
    return match ? match[1] : text;
}

export function initial(text) {
    if (!text) return;
    const [match, initial, end] = `${text}`.match(/^\s*(\S{1,6})(\S*)/);
    return `${initial}${end && end.length > 3 ? '...' : end}`;
}

export function getScrollTop() {
    return window.document.scrollingElement
        ? window.document.scrollingElement.scrollTop
        : window.pageYOffset
}

export function getScrollHeight() {
    return window.document.scrollingElement
        ? window.document.scrollingElement.scrollHeight
        : window.document.body.scrollHeight
}

export function setScrollTop(value) {
    if (window.document.scrollingElement) {
        window.document.scrollingElement.scrollTop = value;
    } else {
        if (window.document.body) {
            window.document.body.scrollTop = value;
        }
        if (window.document.documentElement) {
            window.document.documentElement.scrollTop = value;
        }
    }
}

export function is_touch_device() {
    return 'ontouchstart' in window || navigator.maxTouchPoints;
}

export function is_xs(text) {
    if (typeof(text) === 'undefined') {
        text = true;
    }

    return (document.body.clientWidth < 700) ? text : false;
}

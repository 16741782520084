import {addClickEvent} from '../../helpers';
import wallet_helpers from '../wallet_helpers';

const MAX_PRICE_RANGES = 3;

export default class SeatMap {
    constructor(params) {
        this.params = params;
        this.container = params.container;
        this.seatmap = this.params.seatmap;
        this.events = {};
        this.Mustache = params.Mustache || window.Mustache;
        if (!this.params.templates) {
            this.params.templates = {};
        }
        if (!this.params.templates.seatmap) {
            this.params.templates.seatmap = document.getElementById('seatmap').innerHTML;
        }
    }

    static parsePrice(str) {
        return wallet_helpers.parsePrice(str);
    }

    static currencySign(str) {
        return wallet_helpers.currencySign(str);
    }

    static checkExitRowLeft(cols) {
        const exitCol = cols.findIndex(col => col.isExit);
        const spaceCol = cols.findIndex(col => col.space);
        if (exitCol > 0 && exitCol < spaceCol) {
            cols[0].isExit = true;
            cols[0].classList = ['isExit'];
            return true;
        }
        return false;
    }

    static checkExitRowRight(cols) {
        cols.reverse();
        const result = SeatMap.checkExitRowLeft(cols);
        cols.reverse();
        return result;
    }

    renderSeatmap() {
        const root = this.container;
        if (!this.seatmap ||
            !this.seatmap.seatmap ||
            (!('length' in this.seatmap.seatmap))
        ) {
            root.innerHTML = this.Mustache.render(this.params.templates.seatmap, {});
            return;
        }

        const {seatmap} = this.seatmap;
        let maxRow = 0;
        let startNum = seatmap[0] ? seatmap[0].startNum : 100;
        const view = {
            is_selectable: this.params.is_selectable,
            is_example: this.params.is_example,
            columns: [],
            rows: [],
        };
        const paidStatus = this.getSeatmapLegend();
        const paidClasses = paidStatus.seatPaidClasses;

        seatmap.forEach((column, index) => {
            view.columns.push({
                name: column.rows ? column.seatName : '',
                index
            });
            if (column.seatsCount) {
                maxRow = Math.max(maxRow, column.seatsCount);
                startNum = Math.min(startNum, column.startNum);
            }
        });

        for (let i = 0; i < maxRow; i += 1) {
            const row = {
                i,
                num: i + startNum,
                cols: []
            };

            seatmap.forEach((column, j) => {
                if (!column.rows) {
                    row.cols.push({space: true});
                } else if (column.rows[i]) {
                    const seat = Object.assign({}, column.rows[i]);
                    seat.isExists = 'isExists' in seat
                        ? seat.isExists
                        : true;
                    if (seat.isCurrent && seat.isEmpty) {
                        delete seat.isEmpty;
                    }
                    seat.isOccupied = !seat.isEmpty && !seat.isCurrent;
                    seat.isPaid = seat.isEmpty && seat.isPaid;
                    if (seat.isPaid) {
                        const price = wallet_helpers.parsePrice(seat.cost);
                        seat.cost = price.amount;
                        seat.currency = price.currency;
                        seat.currency_sign = wallet_helpers.currencySign(price.currency);
                        if (paidClasses) {
                            if (price.amount > 0) {
                                seat.seatPaidClass = paidClasses.find(
                                    c => price.amount >= c.min && price.amount <= c.max
                                ).num;
                            } else {
                                seat.seatPaidClass = paidClasses.find(
                                    c => c.isUndefinedPrice === true
                                ).num;
                            }
                        }
                    }
                    seat.classList = Object.keys(seat).filter(
                        key => key.match(/^is/) && seat[key]
                    );
                    seat.index = j;
                    if (seat.isExists) {
                        seat.name = `${column.startNum + i}${column.seatName}`;
                    }
                    row.cols.push(seat);
                } else {
                    row.cols.push({});
                }
            });

            if (!row.cols[0].isExit) {
                SeatMap.checkExitRowLeft(row.cols)
            }
            if (!row.cols[row.cols.length - 1].isExit) {
                SeatMap.checkExitRowRight(row.cols)
            }
            if (row.cols.some(col => col.isExists)) {
                view.rows.push(row);
            }
        }

        const that = this;
        root.innerHTML = this.Mustache.render(
            this.params.templates.seatmap,
            view
        );

        addClickEvent(root, 'tbody td', function (event) {
            that.seatmapClick(this, event);
        });

        if (this.params.is_example) {
            const container = root.querySelector('[data-bind="seatmap"]');
            const svg_text = window.document.getElementById('text-example')
                .innerHTML
                .replace(/>\s+</g, '><')
                .replace(/#/g, '%23').trim();
            container.style.backgroundImage =
                `url('data:image/svg+xml;utf8,${svg_text}')`;
        }
    }

    on(event, callback) {
        this.events[event] = callback;
    }

    off(event) {
        this.events[event] = null;
    }

    getCurrentSeats() {
        const seats = [];
        this.seatmap.seatmap.forEach(col => {
            if (col.rows && col.rows.length) {
                col.rows.forEach((seat, index) => {
                    if (seat.isCurrent) {
                        seats.push((col.startNum + index) + col.seatName);
                    }
                });
            }
        });
        return seats;
    }

    getSeat(name) {
        const m = `${name}`.match(/(\d+)(\w+)|(\w+)(\d+)/);
        if (!m) return null;
        let row;
        let seatName;

        if (parseInt(m[1])) {
            seatName = m[2];
            row = parseInt(m[1]);
        } else {
            seatName = m[3];
            row = parseInt(m[4]);
        }
        if (isNaN(row) || !seatName) return null;

        const foundCol = this.seatmap.seatmap.find(
            col => col.seatName === seatName.toUpperCase()
        );
        return foundCol && foundCol.rows
            && foundCol.rows[row - foundCol.startNum];
    }

    removeActiveSeat() {
        this.seatmap.seatmap.forEach(column => {
            if (!column.rows) return;
            column.rows.forEach(seat => {
                if (seat.isActive) delete seat.isActive;
            });
        });
    }

    setActiveSeat(name) {
        this.removeActiveSeat();
        const seat = this.getSeat(name);
        if (!seat) return;
        seat.isActive = true;
    }

    setSeat(name, state) {
        const seat = this.getSeat(name);
        if (!seat) return;
        Object.keys(seat).forEach(key => delete seat[key]);
        Object.keys(state).forEach(key => {
            seat[key] = state[key];
        });
        return seat;
    }

    getSeatmapLegend(seatmap = this.seatmap) {
        const map = seatmap.seatmap;
        const result = {
            hasPaid: false,
            hasFree: false,
            hasUndefinedPrice: false,
            minPaid: -1,
            maxPaid: 0,
        };
        const costs = {};
        if (!map || !map.length) return result;
        map.forEach(col => {
            if (col.rows && col.rows.length) {
                col.rows.forEach(cell => {
                    if (!cell.isEmpty) return;
                    if (!cell.isPaid) {
                        result.hasFree = true;
                        return;
                    }
                    result.hasPaid = true;

                    const price = wallet_helpers.parsePrice(cell.cost);
                    if (price.amount > 0) {
                        costs[price.amount] = 1;
                        result.currency = price.currency;
                        result.minPaid = result.minPaid > 0
                            ? Math.min(result.minPaid, price.amount)
                            : price.amount;
                        result.maxPaid = Math.max(result.maxPaid, price.amount);
                    } else {
                        result.hasUndefinedPrice = true;
                    }
                });
            }
        });
        result.hasXL = map.some(col => {
            if (col.rows && col.rows.length) {
                return col.rows.some(cell => cell.isXL);
            }
            return false;
        });
        result.hasNorecline = map.some(col => {
            if (col.rows && col.rows.length) {
                return col.rows.some(cell => cell.isNorecline);
            }
            return false;
        });
        result.hasExit = map.some(col => {
            if (col.rows && col.rows.length) {
                return col.rows.some(cell => cell.isExit);
            }
            return false;
        });
        result.min = result.hasFree ? 0 : result.minPaid;
        result.max = result.maxPaid;
        if (result.hasPaid) {
            result.seatPaidClasses = [];
            const ranges = Object.keys(costs)
                .map(cost => parseFloat(cost))
                .sort((a, b) => a - b)
                .map(price => ({min: price, max: price}));
            while (
                ranges.length > MAX_PRICE_RANGES - (result.hasUndefinedPrice ? 1 : 0)
            ) {
                const minGapRange = ranges.reduce((minIndex, _, index) => {
                    if (index < 2) return 1;
                    return (
                        ranges[index].max - ranges[index - 1].min
                        < ranges[minIndex].max - ranges[minIndex - 1].min
                    ) ? index : minIndex;
                }, 1);
                ranges[minGapRange - 1].max = ranges[minGapRange].max;
                ranges.splice(minGapRange, 1);
            }
            if (result.hasUndefinedPrice) {
                ranges.unshift({isUndefinedPrice: true});
            }
            ranges.forEach((range, index) => range.num = index + 1);
            if (result.hasFree) {
                ranges.unshift({isFree: true});
            }
            result.seatPaidClasses = ranges;
        }
        return result;
    }

    seatmapClick(target, event) {
        event.stopPropagation();
        event.preventDefault();

        const col = parseInt(target.dataset.index, 10);
        const row = parseInt(target.closest('tr').dataset.index, 10);
        let column = null;
        if (!isNaN(col) && !isNaN(row) && this.seatmap && this.seatmap.seatmap) {
            column = this.seatmap.seatmap[col];
        }

        if (column) {
            const name = `${row + column.startNum}${column.seatName}`;
            if (typeof (this.events.click) === 'function') {
                this.events.click.call(this, {
                    seat: this.getSeat(name),
                    name
                });
            }
        } else {
            console.log('click to seatmap, column not found ', col, row);
        }
    }
}

import wallet from './components/wallet';
import {toggleClass} from './helpers';

export default class platform {
    constructor(app) {
        this.name = 'web';
        this.id = 'web';
        this.app = app;
        this.settings = {
            COOKIE_NAME: 'cin_sid',
            LANDING_PAGE: 'https://1check.in',
        };
        this.events = {};
    }
    on(eventType, callback) {
        if (!this.events[eventType]) this.events[eventType] = [];
        this.events[eventType].push(callback);
    }
    off(eventType, callback) {
        this.events[eventType].splice(
            this.events[eventType].findIndex(callback), 1
        );
    }
    logout() {
        window.document.cookie =  `${this.settings.COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        try {
            window.localStorage.removeItem('token');
            this.app.settings.token = '';
        } catch (err) {};
        if (window.location.pathname.match(/\w{32}\/$/)) {
            window.location.replace(window.location.pathname
                .replace(/\w{32}\/$/, ''));
        } else {
            this.app.get('login/');
        }
    }
    init() {
        if (location.search && location.search.match(/just_activated/)) {
            this.app.settings.just_activated = true;
        }
        this.app.getUserData(() => {
            if (window.location.hash) {
                this.app.get(window.location.hash.replace(/^#/, ''));
            } else {
                this.app.get(this.app.start_uri);
            }
        }, (userView, status) => userView.onNetworkError(status));
    }
    onReceivePush(payload, callback) {
        this.app.dataStorage.clearNotification();
        if (!payload.url) {
            console.log(`Received notification without url ignoring it`);
            return;
        }
        console.log(`Received notification with url ${payload.url}`);
        // replacing thread by single_thread for status pushes,
        // to navigete on single checkin view instead of group
        if (payload.url.match(/^threads\/\d+\/checkins\/\d+\/$/)) {
            payload.url = payload.url.replace('threads/', 'single_thread/');
        };
        this.app.get(
            payload.url,
            null, null, // class and data
            () => callback && callback(true),
            true // force
        )
    }
    get(uri) {
        const user_profile = this.app.settings.user_profile;
        if (!user_profile || !user_profile.id || uri === 'login/') {
            return 'login/';
        };
        if (!user_profile.is_tos_and_privacy_policy_accepted) {
            return 'user/tos_and_privacy_policy/';
        }
        if (!user_profile.is_active) {
            return 'user/verification/';
        }
        if (!user_profile.has_user_seatmap_strategies) {
            return 'user/seat_preferences/';
        }
        if (!uri) {
            return this.app.start_uri;
        }
        return uri;
    }
    showBoardings(checkins_data) {
        const collapsedContainer = document.getElementById(`files-${checkins_data.id}`);
        if (collapsedContainer) {
            toggleClass(collapsedContainer, 'in');
            return true;
        }

        const file = checkins_data.files[0];
        return this.app.platform.showFile(
            file.id, file.fileurl, file.ext, file.content_type,
            checkins_data.id, file.filetype
        );
        return true;
    }
    showFile(id, url, ext, content_type, checkin_id, filetype) {
        const isBoarding = filetype === 'boardingPass';
        if (isBoarding) {
            this.app.dataStorage.isBPUniq(
                checkin_id,
                () => this.sendEvent('boarding_pass_shown', {checkin_id})
            );
        }
        return true;
    }
    showPassengerStats(id) {
        this.app.get('passenger_stats/');
    }
    showRateDialog() {
        console.log('not implemented');
    }
    openPaymentWindow(url, callback) {
        url += `&lang=${this.app.settings.lang}&allow_dark=true&post_close_window=true`;
        this.openPaymentWindowCallback = callback;
        if (!this.bindPostMessage) {
            window.addEventListener('message', event => {
                if (event.data === 'closeWindow') {
                    this.app.closeModal();
                    if (this.openPaymentWindowCallback) this.openPaymentWindowCallback();
                }
            });
            this.bindPostMessage = true;
        }
        this.app.current_view.loading(false);
        this.app.dialog({
            content: `<iframe src="${url}"></iframe>`,
            subclass: 'modal-dialog-payment',
            onClose: () => {
                if (this.openPaymentWindowCallback) this.openPaymentWindowCallback();
            }
        });
    }
    onNavigate(uri) {
        return;
    }
    checkUploadFilesSupport() {
        return true;
    }

    buySubscription(product_id) {
        this.app.closeModal();
        this.app.dialogComponent(
            'user_subscription_buy_modal/',
            'user_subscription_buy_modal',
            {subclass: 'modal-dialog-seatmap-settings'},
            this.app.current_view
        );
    }

    buyCheckins() {
        this.app.get('user/buy_checkins/');
    }

    paymentComplete() {
        const userData = this.app.settings.user_profile;
        const wasFree = userData.is_free;
        const checkinsBallance = userData.billing && userData.billing.checkin_balance;

        this.app.getUserData(data => {
            if (wasFree && !data.is_free) {
                this.app.updateView('user_subscription_banner/');
                this.app.updateView('user/checkins/success/');
                this.app.get('user/checkins/success/');
            }
            if (checkinsBallance < data.billing.checkin_balance) {
                this.app.updateView('user/checkins/success/')
                this.app.get('user/checkins/success/');
            }
        }, null, true);
    }

    sendEvent(eventName, params) {
        console.log('sendEvent ' + eventName);
        if (window.ya) window.ya.reachGoal(eventName, params);
    }

    setBadge() {}

    share(text, element) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        element.dataset.toggle = 'tooltip';
        element.dataset.title = this.app.lang['copied to buffer'];
        element.style.cssText = '';
        this.app.showToolTip({target: element, stopPropagation: () => {}})
    }

    close() {
        console.error('Close method does not implemented for platrofm web');
    }

    changeLang(lang) {
        const has_lang = location.search.match(/lang=\w+/);
        location.href = location.origin + location.pathname
            + ( has_lang
                    ? location.search.replace(/lang=\w+/, `lang=${lang}`)
                    : location.search + `&lang=${lang}`
            );
    }
}

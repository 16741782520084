import {addClickEvent} from '../helpers';
import SeatMap from './common/SeatMap'
import wallet from './wallet';
import GenericRestComponent from '../GenericRestComponent';

const SKIP = 'SKIP';

export default class seatmap_request extends GenericRestComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'seatmap_request'), data);
        this.endpoint_uri = this.uri.replace(
            /.*(single_thread|checkins)\/(\d+).*/,
            'checkins/$2/seatmap/'
        );
        this.can_blur_prop = true;
        this.checkin_id = parseInt(RegExp.$2, 10);
    }

    can_blur() {
        return this.can_blur_prop;
    }

    get(forceUpdate, callback, fail) {
        const m = this.uri.match(/\?seat=(\w+)/);
        if (m) {
            this.select_seat = m[1];
            this.app.changeObjectURI(this, this.uri.replace(/\?.*/, ''));
        }

        this.checkin_id = this.data.checkin_id || this.checkin_id;
        super.get(true, () => this.getCheckinThread(() => {
            this.initSeatmap();
            if (callback) callback();
        }, fail), fail);
    }

    getCheckinThread(callback, fail) {
        // get checkin data from cache or network
        const cin = this.app.initObject(
            `checkins/${this.checkin_id}/`, 'thread'
        );
        cin.get(false, () => {
            if (!cin) {
                if (fail) fail();
                return;
            }
            this.data.threadObj = cin;
            this.data.thread = this.data.threadObj.data.data;

            if (!this.data.chat && this.app.chat) {
                this.data.chat = this.app.initObject('chats/', 'chat');
            }
            if (this.data.chat) {
                this.chatThread = this.data.clientThreadId
                    ? this.data.chat.findThread(this.data.clientThreadId)
                    : this.data.chat.findCheckinSeatmapThread(this.checkin_id);
                if (this.chatThread) {
                    this.data.clientThreadId = this.chatThread.clientThreadId;
                }
            }
            this.data.can_change_seats = !(
                this.data.thread.is_finished
                || this.data.data.is_checkin_not_active
                || !this.data.data.seatmap
                || (
                    this.chatThread
                    && !this.data.chat.isChatSeatmapRequestActive(
                        this.chatThread, this.data.clientId
                    )
                )
            );
            callback();
        }, fail)
    }

    onNetworkError(status, data) {
        this.loading(false);
        if (status === 422 && data.message_code === 'CheckinNotActive') {
            this.data.can_change_seats = false;
            this.render();
            return;
        }
        super.onNetworkError(status, data);
    }

    submitOnError(form, status, data) {
        if (data.data && data.data.errors) {
            data.message_human = data.data.errors[0].error;
        }
        super.submitOnError(form, status, data);
    }

    initSeatmap() {
        if (!this.data.data.seatmap) return;
        this.seatmap = new SeatMap({
            seatmap: this.data.data.seatmap,
            Mustache: this.app.Mustache,
            is_selectable: this.data.can_change_seats
        });
        this.data.is_skippable = this.data.data.seatmap.is_skippable;
        this.data.has_seatmap = true;
        this.data.seatmap_legend = this.seatmap.getSeatmapLegend();
        if (this.data.seatmap_legend.currency) {
            this.data.seatmap_legend.currency_sign = wallet.currencySign(
                this.data.seatmap_legend.currency
            );
        }
        if (this.data.seatmap_legend.seatPaidClasses) {
            const pc = this.data.seatmap_legend.seatPaidClasses;
            const {currency_sign} = this.data.seatmap_legend;
            this.data.seatPaidClasses = pc.map((c, i) => {
                c.max = c.max > c.min ? c.max : undefined;
                c.delimiter = i % 2 === 1;
                c.currency_sign = currency_sign;
                return c;
            });
        }
        this.data.psg_index = 0;
        this.seatmap.on('click', seat => this.seatmapSeatClick(seat));

        if (this.isInChat) return;

        const {passengers} = this.data.data;
        const seatmapSeats = this.seatmap.getCurrentSeats();
        const hasSeats = passengers.some(psg => psg.seat);
        const hasBoardingSeats = passengers.some(psg => psg.boarding_seat);
        const seatmapSeatsMatchSeats = passengers.every(
            psg => seatmapSeats.find(seat => seat === psg.seat)
        );

        // set passeners seats based on seats selected on seatmap
        if (!hasSeats && !hasBoardingSeats && seatmapSeats.length) {
            passengers.forEach((psg, index) => {
                psg.seat = index < seatmapSeats.length
                    ? seatmapSeats[index]
                    : null;
            });
        }

        // unselect seats on seatmap was selected before
        if (seatmapSeats.length &&
                (hasBoardingSeats
                || (hasSeats && !seatmapSeatsMatchSeats))
        ) {
            seatmapSeats.forEach(seat => {
                const seat_data = this.seatmap.getSeat(seat);
                seat_data.isEmpty = true;
                delete seat_data.isCurrent;
                if(seat_data.isActive) delete seat_data.isActive;
            });
        }

        // select seats on seatmap based on passenger seats or boarding_seats
        if (hasBoardingSeats) {
            passengers.forEach(psg => {
                const seat = this.seatmap.getSeat(psg.boarding_seat);
                if (seat) seat.isCurrent = true;
            });
        }

        // if seat in url
        if (this.select_seat) {
            const seat = {
                name: this.select_seat,
                seat: this.seatmap.getSeat(this.select_seat)
            };
            delete this.select_seat;
            this.seatmapSeatClick(seat);
        }
    }

    calcSeatsCost() {
        this.data.has_paid_seats = false;
        this.data.has_undefined_price = false;
        this.data.seats_cost = 0;
        this.data.seats_cost_currency = 'RUB';

        const passengers = this.data.data.passengers || [];

        passengers.forEach((psg, index) => {
            psg.index = index;
            psg.is_active = (index === this.data.psg_index);
            let seat = null;
            if (psg.seat !== SKIP) {
                seat = this.seatmap.getSeat(psg.seat);
                if (!seat || (!seat.isEmpty && !seat.isCurrent)) {
                    delete psg.seat;
                    seat = null;
                }
            }

            if (
                seat && seat.isPaid && psg.age_class !== 'infant'
            ) {
                const price = wallet.parsePrice(seat.cost);
                this.data.seats_cost_currency = price.currency;
                this.data.seats_cost += price.amount;
                this.data.has_paid_seats = true;
                psg.seat_cost = price.amount;
                psg.seat_currency = price.currency;
                psg.seat_currency_sign = wallet.currencySign(price.currency);
                if (!price.amount) {
                    this.data.has_undefined_price = true;
                }
            } else {
                if (psg.seat_cost) delete psg.seat_cost;
                if (psg.seat_currency) delete psg.seat_currency;
                if (psg.seat_currency_sign) delete psg.seat_currency_sign;
            }
        });

        this.data.data.infants = passengers.filter(
            psg => psg.age_class === 'infant'
        );
        this.data.data.adults = passengers.filter(
            psg => psg.age_class !== 'infant'
        );
        this.data.data.infants.forEach((infant, index) => {
            this.data.data.adults[index].infant = infant;
        });

        if (this.includeCommission) {
            this.data.total_seats_cost = this.data.seats_cost;
            this.data.seats_cost_currency_sign = wallet.currencySign(
                this.data.seats_cost_currency
            );
            return;
        }
        if (this.data.has_paid_seats && this.data.seats_cost) {
            this.data.seats_cost_commission = wallet.getCommission(
                this.data.seats_cost, this.data.seats_cost_currency
            );
            this.data.total_seats_cost = this.data.seats_cost + this.data.seats_cost_commission;
            this.data.seats_cost_currency_sign = wallet.currencySign(
                this.data.seats_cost_currency
            );
        } else {
            this.data.total_seats_cost = 0;
        }
    }

    render(level) {
        if (this.data.data.is_checkin_not_active || !this.seatmap) {
            super.render(level);
            return;
        }
        this.calcSeatsCost();
        const adults = this.data.data.adults || [];
        this.data.submitDisabled = !adults.every(psg => psg.seat);
        this.data.hasSeats = adults.some(psg => psg.seat);
        let oldSeatmapContainer;

        if (this.view) {
            if (this.view.clientHeight) {
                this.view.style.height = `${this.view.clientHeight}px`;
            }
            oldSeatmapContainer = this.view.querySelector('[data-bind="seatmap-container"]');

            if (oldSeatmapContainer) {
                oldSeatmapContainer.dataset.scrollTop = oldSeatmapContainer.scrollTop;
                const div = document.createElement('div');
                div.appendChild(oldSeatmapContainer);
            }
        }
        super.render(level);

        const newContainer = this.view.querySelector('[data-bind="seatmap-container"]');
        if (oldSeatmapContainer) {
            newContainer.replaceWith(oldSeatmapContainer);
            oldSeatmapContainer.scrollTop = oldSeatmapContainer.dataset.scrollTop;
            this.view.style.height = 'auto';
        }

        if (this.seatmap) {
            this.seatmap.container = oldSeatmapContainer || newContainer;
            this.seatmap.renderSeatmap();
        }

        this.formSubmitToggle(this.data.submitDisabled);
    }

    seatmapSeatClick({seat, name}) {
        if (!seat.isEmpty && !seat.isCurrent) return;
        if (!this.data.can_change_seats) return;
        const {adults} = this.data.data;
        const {psg_index} = this.data;
        const currentPsg = adults[psg_index];
        let nextPsg = adults[(psg_index + 1) % adults.length];
        if (seat.isCurrent) {
            nextPsg = adults.find(psg => psg.seat === name);
            if (nextPsg && nextPsg !== currentPsg) {
                nextPsg.seat = null;
            }
        }
        if (currentPsg.seat && currentPsg.seat !== SKIP) {
            const prevSeat = this.seatmap.getSeat(currentPsg.seat);
            if (prevSeat) {
                delete prevSeat.isCurrent;
                prevSeat.isEmpty = true;
            }
        }
        currentPsg.seat = name;
        seat.isCurrent = true;
        if (seat.isEmpty) delete seat.isEmpty;
        this.data.psg_index = adults.indexOf(nextPsg);
        this.changes = true;
        this.render();
    }

    skip(event) {
        if (!this.app.confirm(this.skipSeatConfirm)) return;
        this.data.data.adults.forEach(psg => {
            psg.seat = SKIP;
        });
        this.formSubmit(this.view.querySelector('form'), event);
    }

    bindLinks() {
        super.bindLinks();
        if (this.seatmap) {
            this.app.bindTooltips(this.seatmap.container);
        }

        const that = this;
        addClickEvent(that.view, '[data-bind="skip"]', event => this.skip(event));
        addClickEvent(this.view, '[data-bind="psg"]', event => {
            this.data.psg_index = parseInt(
                event.target.closest('[data-bind="psg"]').dataset.index, 10
            );
            const psg = this.data.data.adults[this.data.psg_index];
            if (!psg.seat) {
                this.seatmap.removeActiveSeat();
            } else {
                this.seatmap.setActiveSeat(psg.seat);
            }
            this.render();
        });
        addClickEvent(this.view, '[data-bind="chat-new-thread"]', event => {
            const target = event.target.closest('[data-bind="chat-new-thread"]');
            const threadType = target.dataset.type;
            if (this.data.threadObj) {
                this.data.threadObj.createNewChatServiceThread(
                    threadType, target.textContent.trim()
                );
            } else if (this.data.chat && this.data.clientThreadId) {
                const text = document.getElementById('chatMessage-seat_change').innerText;
                this.data.chat.sendMessage({text}, this.data.clientThreadId);
            } else {
                console.error('Can\'t create chat thread, no thread found and no chat');
            }
            this.app.closeModal();
        });
    }

    updateCheckinThreadSeats(data) {
        if (!this.data.threadObj) return;

        this.data.threadObj.data.data.passengers.forEach(psg => {
            if (!psg || !psg.data) return;
            const p = data.find(p => psg.data.data.psg_id == p.psg_id);
            if (p) {
                psg.data.data.seat = p.seat;
            }
        });
        // rerender view, then in will be shown
        if (this.data.threadObj.view) {
            this.data.threadObj.render();
        }
    }

    formSubmit(form, event) {
        event.preventDefault();
        this.calcSeatsCost();

        const {passengers} = this.data.data;
        const is_skip = passengers.some(psg => psg.seat === SKIP);
        const data = [];
        let text = ''; // text for chat

        passengers.forEach(psg => {
            data.push({psg_id: psg.id, seat: psg.seat});
            if (psg.infant) {
                data.push({psg_id: psg.infant.id, seat: psg.seat, is_infant: true});
            }
        });
        this.updateCheckinThreadSeats(data);

        if (is_skip) {
            text = this.langSkip;
        } else {
            text = passengers.map(
                psg => `${psg.first_name} ${psg.last_name}: ${psg.seat}`
            ).join('\n');
        }

        if (this.data.has_paid_seats && !is_skip) {
            if (!this.data.has_undefined_price) {
                text += `\n${this.data.total_seats_cost} ${
                    this.data.seats_cost_currency
                }`;
            }

            // force send to chat even if chat is closed
            // then paid seats chosen

            if (!this.data.clientThreadId) {
                this.data.threadObj.createNewChatServiceThread('seat_change');
            }
        }

        if (this.data.clientThreadId) {
            this.data.chat.sendMessage(
                {text, inReplyTo: this.data.clientId},
                this.data.clientThreadId
            );
        }

        if (this.data.has_paid_seats) {
            // do not send seats update
            // if seat are paid
            this.app.closeModal();
            if (this.data.clientThreadId) {
                this.app.get(`chats/clientThreadId/${this.data.clientThreadId}/`);
            }
            return;
        }

        this.loading(true);
        this.formSubmitToggle(false);
        this.changes = false;
        this.app.ajax({
            url: this.app.settings.endpoints + this.endpoint_uri,
            method: this.is_new ? 'POST' : 'PUT',
            data: {data},
            callback: () => {
                this.loading(false);
                this.update();
                this.formSubmitToggle(true);
                this.app.closeModal();
            },
            error: (status, errors) => {
                this.submitOnError(form, status, errors);
                this.formSubmitToggle(true);
            }
        });
    }
}

import {addClickEvent, addEvent, deepcopy, toggleClass} from '../helpers';
import GenericComponent from '../GenericComponent';
import DragList from './common/DragList';

const MAX_DEFAULT_STRATEGIES = 2;

export default class seatmap_strategies extends GenericComponent {
    constructor(uri, app, className, data) {
        super(uri, app, (className || 'seatmap_strategies'), data);
        this.default_modifiers = {front: true, middle: true, rear: true};
        this.css_prefix = this.app.settings.cssPrefix
            && this.app.settings.cssPrefix.prefix || '';
    }

    get(forceUpdate, callback, fail) {
        const strategiesDir = {};
        this.data.strategies.forEach(strategy => {
            if (!strategy) return;
            strategiesDir[strategy.id] = strategy;
        });
        const defaultList = this.data.strategies.map(strategy => strategy.id)
            .splice(0, Math.min(
                this.data.strategies.length,
                this.app.settings.max_default_strategies || MAX_DEFAULT_STRATEGIES
            ));
        const strategiesList = deepcopy(
            this.data.data && this.data.data.length
                ? this.data.data
                : defaultList
        );
        const str_count = strategiesList.indexOf('find_any_seat') >= 0
            ? strategiesList.indexOf('find_any_seat') + 1
            : strategiesList.length;
        this.data.strategiesList = strategiesList.map((strategy, i) => {
            let str_name, modifiers;
            if (typeof (strategy) === 'string') {
                str_name = strategy;
                modifiers = this.default_modifiers;
            } else {
                str_name = strategy.name;
                modifiers = strategy.modifiers || this.default_modifiers;
            }
            if (!strategiesDir[str_name]) {
                console.error(`unknown strategy ${str_name}`);
                return {strategy: str_name, index: i + 1};
            }
            const tmp = deepcopy(strategiesDir[str_name]);
            if (tmp.has_modifiers) tmp.modifiers = modifiers;
            tmp.index = i + 1;
            tmp.strategy = tmp.id;
            return tmp;
        }).splice(0, str_count);
        this.data.cant_delete = strategiesList.length <= 1;

        if (typeof (callback) === 'function') {
            callback();
        }
        return this;
    }

    applyStrategy(strategies) {
        const strData = {};
        const seatmap = this.seatmap_preview();
        const persons = this.data.num;
        const error = seatmap.view.querySelector('[data-bind="seats-not-found"]');
        strData[persons] = strategies;
        this.app.ajax({
            method: 'POST',
            data: {
                data: {
                    seatmap: seatmap.seatmap.seatmap,
                    strategies: strData,
                    persons
                }
            },
            url: `${this.app.settings.endpoints}seatmap/apply_strategy/`,
            callback: data => {
                seatmap.clearCurrentSeats();
                if (!data || !data.data || !data.data.length) {
                    error.classList.add('in');
                } else {
                    seatmap.setCurrentSeats(data.data);
                    error.classList.remove('in');
                }
                if (this.app.dialog) {
                    // in widget we have no dialogs
                    this.app.dialog({
                        name: this.example,
                        id: `psg-${persons}-seatmap-preview`,
                        content: seatmap.view.innerHTML
                    });
                }
            }
        });
    }

    seatmap_preview() {
        return this.app.getObject(
            this.view.querySelector('.obj-seatmap_preview').dataset.uri
        );
    }

    static serializeModifiers(plane) {
        let all_true = true;
        let all_false = true;
        const modifiers = {};
        plane.querySelectorAll('input').forEach(plane_selector => {
            modifiers[plane_selector.name] = plane_selector.checked;
            all_true = all_true && plane_selector.checked;
            all_false = all_false && !plane_selector.checked;
        });
        return (all_true || all_false) ? null : modifiers;
    }

    open() {
        if (this.opened) return;
        this.opened = true;
        this.strategiesChange();
    }

    strategiesChange(event) {
        const strategiesWithState = [];
        const strategies = Array.prototype.map.call(
            this.view.querySelectorAll('[name="strategy"]'),
            ctl => ctl.value
        );
        this.view.querySelectorAll('[data-bind="plane-selector"]').forEach(
            (plane, i) => {
                const {has_modifiers} = this.data.strategies.find(
                    dic => dic.id === strategies[i]
                );
                let modifiers = has_modifiers
                    ? seatmap_strategies.serializeModifiers(plane)
                    : null;
                if (modifiers) {
                    strategies[i] = {name: strategies[i], modifiers};
                } else if (has_modifiers) {
                    modifiers = this.default_modifiers;
                }
                toggleClass(plane, 'hidden', !has_modifiers);
                strategiesWithState.push({
                    index: i + 1,
                    strategy: strategies[i],
                    modifiers,
                    has_modifiers
                });
            }
        );
        this.data.strategiesList = strategiesWithState;
        this.formSubmitToggle(false);
        this.data.data = strategies;
        this.data.cant_delete = strategies.length <= 1;
    }

    initForm() {
        const seatmap_container = this.view.querySelector('[data-bind="seatmap-container"]');
        const dlist = new DragList(this.view.querySelector('[data-bind="drag-list"]'), {
            drag_holder_class: `${this.css_prefix}drag-holder`,
            drag_class: `${this.css_prefix}drag`,
            dragging_class: `${this.css_prefix}dragging`,
        });

        const that = this;
        dlist.on('change', event => this.strategiesChange(event));
        addClickEvent(this.view, '[data-bind="apply"]', () => {
            this.strategiesChange();
            this.applyStrategy(this.data.data);
        });
        addClickEvent(this.view, '[data-bind="add-strategy"]', event => {
            event.preventDefault();
            const {has_modifiers} = this.data.strategies[0];
            this.data.strategiesList.push({
                index: this.data.strategiesList.length,
                strategy: this.data.strategies[0].id,
                modifiers: has_modifiers ? this.default_modifiers : null,
                has_modifiers
            });
            this.data.cant_delete = this.data.strategiesList.length <= 1;
            this.render();
            this.strategiesChange(event);
        });
        addClickEvent(this.view, '[data-bind="remove"]', event => {
            const parent = event.target.closest('[data-bind="drag"]');
            parent.remove();
            this.strategiesChange(event);
            if (this.data.strategiesList.length <= 1) this.render();
        });
        addEvent(this.view.querySelectorAll('[name="strategy"]'), 'change', event => {
            event.stopImmediatePropagation();
            this.strategiesChange(event);
        });
        addClickEvent(this.view, '[data-bind="show"]', event => {
            event.preventDefault();
            const parent = event.target.closest('[data-bind="drag"]');
            const strategy = this.data.strategies.find(
                dic => dic.id === parent.querySelector('[name="strategy"]').value
            );
            const modifiers = seatmap_strategies.serializeModifiers(
                parent.querySelector('[data-bind="plane-selector"]')
            );
            if (strategy.has_modifiers && modifiers) {
                this.applyStrategy([{
                    name: strategy.id, modifiers
                }]);
            } else {
                this.applyStrategy([strategy.id]);
            }
        });
        addClickEvent(this.view, '[data-bind="back-preview"]', event => {
            event.preventDefault();
            seatmap_container.classList.remove('in');
            this.app.scrollTo(this.view);
        });
        super.initForm();

        // after super.initForm() because need to check inputs before serialization
        addClickEvent(
            this.view,
            '[data-bind="plane-selector"] button',
            function planeClick(event) {
                const plane = this.closest('[data-bind="plane-selector"]');
                if (
                    !this.classList.contains('active')
                    && !seatmap_strategies.serializeModifiers(plane)
                ) {
                    console.log('can\'t uncheck last plane section');
                    this.classList.add('active');
                    this.querySelector('input').checked = true;
                    return;
                }
                that.strategiesChange(event);
            }
        );
    }

    formSubmit(form, event) {
        this.parent.formSubmit(form, event);
    }
}

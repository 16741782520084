import Mustache from 'mustache';
import flatpickr from 'flatpickr';
import {
    is_xs, date_format, time_format, smart_time, smart_time_utc,
    i18n, date_time_format, formatPrice,
    last_4_digits, initial, capitalize, if_mobile_app, time_delta
} from './helpers';

// #if !process.env.MOBILE_APP
export function configureReformal(lang) {
    if (lang !== 'ru') {
        return;
    }
    window.reformalOptions = {
        project_id: 977891,
        project_host: 'feedback.1check.in',
        tab_orientation: 'left',
        tab_indent: '50%',
        tab_bg_color: '#36abc8',
        tab_border_color: '#FFFFFF',
        tab_image_url: 'http://tab.reformal.ru/T9GC0LfRi9Cy0Ysg0Lgg0L%252FRgNC10LTQu9C%252B0LbQtdC90LjRjw==/FFFFFF/4bfb34d91c8d7fb481972ca3c84aec38/left/0/tab.png',
        tab_border_width: 2
    };
    const startReformal = () => {
        const script = document.createElement('script');
        const protocol = document.location.protocol === 'https:' ? 'https://' : 'http://';
        script.type = 'text/javascript';
        script.async = true;
        script.src = `${protocol}media.reformal.ru/widgets/v3/reformal.js`;
        document.getElementsByTagName('head')[0].appendChild(script);
    };
    if (!is_xs()) {
        startReformal();
    }
}
// #endif

export function configureFlatpickr(lang) {
    console.log('configureFlatpickr ' + lang);
    if (lang === 'ru') {
        flatpickr.l10ns.ru = {};
        flatpickr.l10ns.ru.firstDayOfWeek = 1;
        flatpickr.l10ns.ru.weekdays = {
            shorthand: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            longhand: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота']
        };
        flatpickr.l10ns.ru.months = {
            shorthand: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
            longhand: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
        };
        flatpickr.localize(flatpickr.l10ns.ru);
    };
    flatpickr.defaultConfig.dateFormat = 'd.m.Y';
    flatpickr.defaultConfig.datePlaceholder = '__.__.____';
    flatpickr.defaultConfig.dateExample = '10.10.2020';
    flatpickr.defaultConfig.datePattern = '\\d{2}\\.\\d{2}\\.\\d{4}';
    flatpickr.defaultConfig.time_24hr = true;

    return flatpickr;
}

export function configureMustache() {
    //  mustache helpers injection
    Mustache.tags = ['<%', '%>'];
    Mustache.Context.prototype._lookup = Mustache.Context.prototype.lookup;
    Mustache.Context.prototype.lookup = function (name) {
        const helpers = function (func) {
            const functions = {
                formatPrice,
                date_format,
                smart_time,
                smart_time_utc,
                time_format,
                date_time_format,
                i18n,
                last_4_digits,
                initial,
                capitalize,
                is_xs,
                if_mobile_app,
                time_delta
            };
            const tmp = functions[func];
            return tmp ?
                function (name, render) {
                    return tmp.call(this, render(name));
                }
                :
                null;
        };
        return helpers(name) || this._lookup(name);
    };
    return Mustache;
}

export function configureOneSignal(config, user_id) {
    if (window.location.protocol !== 'https:') {
        return;
    }
    const OneSignal = window.OneSignal || [];
    config.notifyButton.displayPredicate =  () => {
        return OneSignal.isPushNotificationsEnabled()
            .then(function(isPushEnabled) {
                return !isPushEnabled;
            });
    };
    OneSignal.push(['init', config]);
    OneSignal.push(() => OneSignal.sendTag('user_id', user_id));
    OneSignal.push(() => OneSignal.showHttpPrompt());
    OneSignal.push(() => {
        OneSignal.on('notificationDisplay', event => {
            window._app.platform.onReceivePush(event.data);
        });
    });
}

export function configureMetrika(id) {
    const d = document;
    const w = window;
    const c = 'yandex_metrika_callbacks';
    (w[c] = w[c] || []).push(function() {
        try {
            w.ya = new Ya.Metrika({
                id,
                clickmap: false,
                trackLinks: false,
                accurateTrackBounce: false,
                webvisor: false,
                trackHash: true,
            });
        }
        catch (e) {}
    });

    var n = d.getElementsByTagName("script")[0],
        s = d.createElement("script"),
        f = function()
        {
            n.parentNode.insertBefore(s, n);
        };
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://mc.yandex.ru/metrika/watch.js";

    if (w.opera == "[object Opera]") {
        d.addEventListener("DOMContentLoaded", f, false);
    } else {
        f();
    }
}

